class NewCommunity extends View {
	constructor(gettextCatalog) {
		super();

		this.stepData = [
	        { step: 1, completed: false, optional: false, data: {} },
	        { step: 2, completed: false, optional: false, data: {} },
	        { step: 3, completed: false, optional: false, data: {} },
	        { step: 4, completed: false, optional: false, data: {} }
	    ];

	    this.stepProgress = 1;

		this.communityTypes = [
			{name: gettextCatalog.getString('Public'), value: 'PUBLIC'},
			{name: gettextCatalog.getString('Confidential'), value: 'PRIVATE'}
			//{name: gettextCatalog.getString('Anonymous'), value: 'ANONYMOUS'}
		];

		this.memberTypes = [
			{name: gettextCatalog.getString('Parents'), value: 'PARENTS'},
			{name: gettextCatalog.getString('Sitters'), value: 'SITTERS'},
			{name: gettextCatalog.getString('Parents and Sitters'), value: 'MIXED'}
		];

		this.community = {};
	}

	uploadCompleted(v) {
		this.invoke(function($mdDialog, gettextCatalog) {
			'ngInject';

			this.community.badge = v.secure_url;

			var img = new Image();
			img.onload = () => {
				//To change and add crop image
				this.imageOk = true;
				if(img.width != img.height) {
					//this.imageOk = false;
					$mdDialog.show($mdDialog.alert()
				        .parent(angular.element(document.body))
				        .title(gettextCatalog.getString('Your image size is not ideal'))
				        .textContent(gettextCatalog.getString('We recommend you use a square formated image (eg: 200x200)'))
				        .ariaLabel(gettextCatalog.getString('Your image size is not ideal'))
				        .ok(gettextCatalog.getString('Ok'))
				    );
				} else {
					this.imageOk = true;
				}
			}

			img.src = v.secure_url;
		})
		
	}

	enableNextStep() {
        if (this.selectedStep >= this.maxStep) {
            return;
        }

        if (this.selectedStep === this.stepProgress - 1) {
            this.stepProgress = this.stepProgress + 1;
        }
        this.selectedStep = this.selectedStep + 1;
    }

    previousStep() {
        if (this.selectedStep > 0) {
            this.selectedStep = this.selectedStep - 1;
        }
    }

    submitCurrentStep(stepData, isSkip) {
    	this.invoke(function($mdToast, $q, $timeout, gettextCatalog) {
			'ngInject';

			if(stepData.step === 1 && !this.imageOk) {
				$mdToast.show(
                    $mdToast.simple()
                    .textContent(gettextCatalog.getString('You need to provide a profile picture for your community'))
                    .position('bottom right')
                    .hideDelay(4000)
                );

				return;
			}

			var deferred = $q.defer();
	        this.showBusyText = true;
	        if (!stepData.data.completed && !isSkip) {
	            //simulate $http
                this.showBusyText = false;
                deferred.resolve({ status: 200, statusText: 'success', data: {} });
                //move to next step when success
                stepData.data.completed = true;
                this.enableNextStep();
	        } else {
	            this.showBusyText = false;
	            this.enableNextStep();
	        }
		})
    }

	saveCommunity() {
		this.invoke(($rootScope, $loader, $state, $mdToast, CommunityService, gettextCatalog) => {
			'ngInject';

			$loader.show();

			CommunityService.save(this.community).success((c) => {
				$loader.hide();
                $mdToast.show(
                    $mdToast.simple()
                    .textContent(this.community.name + ' ' + gettextCatalog.getString('saved'))
                    .position('bottom right')
                    .hideDelay(3000)
                );

                $rootScope.$emit('menu:refresh');
                $state.go('community', {id: c._id});
            })
            .error((err) => {
            	$loader.hide();
                $mdToast.show(
                    $mdToast.simple()
                    .textContent(gettextCatalog.getString('Error saving community'))
                    .position('bottom right')
                    .hideDelay(3000)
                );
            });
		})
	}
}

View.register(NewCommunity);