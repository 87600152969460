class FacebookLogin extends Service {
	constructor() {
		super();
	}

	login() {
		console.log('LOGIN FB');
		return this.invoke(function($mdDialog, $loader) {
			'ngInject';

			return new Promise((resolve, reject) => {
				FB.login((response) => {
					console.log('LOGIN RES', response);
		         	if (response.authResponse) {
		         		FB.api('/me', {fields: 'email,first_name,last_name'}, (me) => {
							console.log('ME', me);

							if(!me.email) {
								$loader.hide();
								var confirm = $mdDialog.prompt()
							      .title('Email addres missing')
							      .textContent('We could not retrieve your email address using Facebook')
							      .placeholder('Enter your Email')
							      .ariaLabel('Email')
							      .ok('Save')
							      .cancel('Cancel');

							    $mdDialog.show(confirm).then((email) => {
							    	$loader.show();
							    	console.log('email', email);
							    	resolve({
							 			reference: {
							 				name: 'facebook',
							 				value: response.authResponse.userID ? response.authResponse.userID: response.authResponse.userId
							 			},
							 			token: response.authResponse.accessToken,
							 			email: email,
							 			firstName: me.first_name,
							 			lastName: me.last_name
							 		})
							    }, function() {
							      	reject();
							    });
							} else {
								resolve({
						 			reference: {
						 				name: 'facebook',
						 				value: response.authResponse.userID ? response.authResponse.userID: response.authResponse.userId
						 			},
						 			token: response.authResponse.accessToken,
						 			email: me.email,
						 			firstName: me.first_name,
						 			lastName: me.last_name
						 		})
							}
						});
					 } else {
					 	reject();
					 }
		        }, {scope: 'email,user_managed_groups'})
			})
		})
	}

	logout() {
		return Promise.resolve();
	}
}

Service.register(FacebookLogin);

class FacebookService extends Service {
	constructor() {
		super();
	}

	getGroups() {
		'ngInject';

		this.invoke((Session) => {
			var user = Session.getUser();
			setTimeout(function() {
				FB.getLoginStatus((res) => {
					console.log('login status', res)

					FB.api('/' + user.reference.value + '/permissions', (res) => {
						console.log('permissions', res);
					})
					FB.api('/' + user.reference.value + '/groups', (res) => {
						console.log('response groups', res)
					});

					FB.api('/1398301017059005/members', (res) => {
						console.log('group members', res)
					});
				});
				
			}, 1000);
			
		})
	}
}

Service.register(FacebookService);