class CommunityService extends Service {
    constructor() {
        super();
    }

    get(id) {
    	return this.query({id: id});
    }

    getStats(id, timeFrame) {
        return this.invoke(function($http, $config) {
            'ngInject';

            return $http.get($config.apiUrl + '/community-stats', {params: {id: id, timeFrame: timeFrame}});
        });
    }

    query(params) {
    	return this.invoke(function($http, $config) {
            'ngInject';

            return $http.get($config.apiUrl + '/community', {params: params});
        });
    }

    save(community) {
        return this.invoke(function($http, $config) {
            'ngInject';

            return $http.post($config.apiUrl + '/community', community);
        });
    }

    changeStatus(community, status) {
        return this.invoke(function($http, $config) {
            'ngInject';

            return $http.post($config.apiUrl + '/community', {community: community, newStatus: status});
        });
    }
}

Service.register(CommunityService);