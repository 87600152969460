class Intro extends View {
	constructor() {
		super();
	}
	login() {
		this.invoke(function($loader, $state, SessionService, popup, gettextCatalog) {
			'ngInject';
			var user = {reference: {name: 'facebook'}};
			$loader.show();
			SessionService.login(user).then(
				(session) => {
					$state.go('home');
					this.user = {};
					$loader.hide();
				}, 
				(err) => {
					$loader.hide();
					popup.alert(
						gettextCatalog.getString('Login failed'),
						gettextCatalog.getString('Make your email is correct')
					)
				}
			)
		})
	}
}
View.register(Intro);


// class Intro extends View {
// 	constructor(uuid) {
// 		super();

// 		this.user = {};
// 		this.uuid = uuid;
// 	}

// 	register() {
// 		this.user.reference = {name: 'self', value: this.uuid()};
// 		this.user.register = true;
// 		var self = this;

// 		this.invoke(function($loader, $store, $state, SessionService, popup, gettextCatalog) {
// 			if (this.user.newPassword !== this.user.newPasswordAgain) {
// 				$loader.hide();

// 				popup.alert(
// 					gettextCatalog.getString('Login failed'),
// 					gettextCatalog.getString('Make sure your passwords match')
// 				)
// 			} else {
// 				this.user.password = this.user.newPassword;
// 				SessionService.login(this.user).then((session) => {
// 					$state.go('home');
// 				})
// 			}
// 		})
// 	}

// 	login(reference) {
// 		this.invoke(function($loader, $state, SessionService, popup, gettextCatalog) {
// 			'ngInject';

// 			this.user.reference = {name: reference};

// 			$loader.show();

// 			SessionService.login(this.user).then(
// 				(session) => {
// 					$state.go('home');

// 					this.user = {};
// 					$loader.hide();
// 				}, 
// 				(err) => {
// 					$loader.hide();

// 					popup.alert(
// 						gettextCatalog.getString('Login failed'),
// 						gettextCatalog.getString('Make your email is correct')
// 					)
// 				}
// 			)
// 		})
// 	}
// }

// View.register(Intro);
