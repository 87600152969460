class Menu extends View {
	constructor($rootScope, gettextCatalog, CommunityService) {
		'ngInject';

		super();
		
		this.sections = [
			{
				name: gettextCatalog.getString('Dashboard'),
				class: 'md-button-link',
				state: 'home',
				type: 'link'
	        }, {
	          name: gettextCatalog.getString('Communities'),
	          type: 'toggle',
	          pages: []
	        }, {
				name: gettextCatalog.getString('New community'),
				class: 'md-button-link',
				state: 'new-community',
				icon: 'fa fa-plus',
				type: 'link'
	        }
        ];

        this.openedSection = {
        	name: gettextCatalog.getString('Communities')
        }

		$rootScope.$on('menu:refresh', () => {
			CommunityService.query().success((res) => {
				this.sections[1].pages = [];
				res.forEach((c) => {
					this.sections[1].pages.push({
			            name: c.name,
			            type: 'link',
			            state: 'community({id: "' + c._id + '"})',
			            sateId: c._id
		          	})
				})
			})
		})
	}

	isOpen(section) {
		if(this.openedSection && section) return this.openedSection.name === section.name;
	};

	toggleOpen(section) {
		this.openedSection = (this.openedSection === section ? null : section);
	}

	openMenu($mdOpenMenu, ev) {
		this.invoke(() => {
			'ngInject';
			$mdOpenMenu(ev);
		})
	}

	toggleMenu() {
		this.invoke(($mdSidenav) => {
			$mdSidenav('right').toggle();
		})
	}

	showSettings(ev) {
		this.invoke(SettingsService => {
			'ngInject';

			SettingsService.show(ev);
		})
	}

	isLogged() {
		var loggedIn;
		this.invoke((SessionService) => {
			'ngInject';

			loggedIn = SessionService.isLoggedIn();
		})

		return loggedIn;
	}

	logout() {
		this.invoke((popup, gettextCatalog, SessionService, $state) => {
			'ngInject';

			popup.confirm(
				gettextCatalog.getString('Logout'),
				gettextCatalog.getString('Are you sure?')
			).then((res) => {
				if (res) SessionService.logout().then(() => $state.go('intro'));
			})
		})
	}
}

//The menu is a view without a route
View.register(Menu, {
	noroute: true
});

// angular.module().directive('menu', function() {
// 	return {
// 		templateUrl: 'menu.html',
// 		replace: true,
// 		scope: {
// 		},
// 		controller: 'MenuController'
// 	}
// })