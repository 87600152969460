angular.module().constant('$config', window.$APP_CONFIG);

angular.module().directive('body', function($timeout, $config, $globalization, SessionService, gettextCatalog) {
	return {
		restrict: 'E',
		templateUrl: 'app.html',
		link: function($scope, $element, $attrs) {
			$timeout(function() {
				let language = 'en';

				if (SessionService.getSession().getUser()) {
					language = SessionService.getSession().getUser().language || 'en';
				}

				gettextCatalog.setCurrentLanguage(language);

				SessionService.on('changed', () => {
					var lang = SessionService.getSession().getUser().language;
					
					if (gettextCatalog.getCurrentLanguage() !== lang) {
						gettextCatalog.setCurrentLanguage(lang);
					}
				})
			}, 0)
		}
	}
})

angular.module().config(function($mdThemingProvider) {
	$mdThemingProvider.definePalette('myBsitPalette', {
		'50': '01d286',
		'100': '01d286',
		'200': '01d286',
		'300': '01d286',
		'400': '01d286',
		'500': '01d286',
		'600': '01d286',
		'700': '01d286',
		'800': '01d286',
		'900': '01d286',
		'A100': '01d286',
		'A200': '01d286',
		'A400': '01d286',
		'A700': '01d286',
		'contrastDefaultColor': 'light',    // whether, by default, text (contrast)
		                            		// on this palette should be dark or light
		'contrastDarkColors': ['50', '100', //hues which contrast should be 'dark' by default
		'200', '300', '400', 'A100'],
		'contrastLightColors': undefined    // could also specify this if default was 'dark'
	});
})

angular.module().config(function($mdThemingProvider) {
	$mdThemingProvider.theme('default').primaryPalette('myBsitPalette').accentPalette('blue');
})

angular.module().config(function($urlRouterProvider) {
	$urlRouterProvider.otherwise('/intro');
})

angular.module().run(function(SessionService, $state) {
	angular.element(document).ready(function () {
		if (!SessionService.isLoggedIn()) {
			View.onRegister('Intro', () => $state.go('intro'))
		} else {
			View.onRegister('Home', () => $state.go('home'))
		}
	})
})

angular.module().run(function($rootScope) {
	$rootScope.$on('$stateChangeSuccess', function(event, toState, toParams, fromState, fromParams) {
		if (fromState && fromState.name) document.body.classList.remove(fromState.name);
		if (toState && toState.name) document.body.classList.add(toState.name);	
	})
})

//Init facebook plugin
angular.module().run(function($config) {
	window.fbAsyncInit = function() {
		FB.init({
		appId : $config.facebook,
		xfbml : true,
		version : 'v2.7'
		});
	};

	(function(d, s, id){
		var js, fjs = d.getElementsByTagName(s)[0];
		if (d.getElementById(id)) {return;}
		js = d.createElement(s); js.id = id;
		js.src = "https://connect.facebook.net/en_US/sdk.js";
		fjs.parentNode.insertBefore(js, fjs);
	}(document, 'script', 'facebook-jssdk'));
})