class Home extends View {
	constructor(gettextCatalog) {
		super();

		this.timeFrames = [
			{value: 'week', name: gettextCatalog.getString('Last week')},
			{value: '2weeks', name: gettextCatalog.getString('Last two weeks')},
			{value: 'month', name: gettextCatalog.getString('Last month')}
		]

		this.selectedTimeFrame = this.timeFrames[0].value;
	}

	afterEnter($rootScope, Watcher, FacebookService, CommunityService) {
		'ngInject';

		if(!this.chartWatcher) {
            this.chartWatcher = Watcher.watch(() => this.selectedCommunity, (c) => {
            	if(c) {
            		CommunityService.getStats(c, this.selectedTimeFrame).success(res => {
						this.initChart(res);
					});
            	}
            }, true);
        }

        if(!this.timeFrameWatcher) {
            this.timeFrameWatcher = Watcher.watch(() => this.selectedTimeFrame, (t) => {
            	if(t) {
            		CommunityService.getStats(this.selectedCommunity, t).success(res => {
						this.initChart(res);
					});
            	}
            }, true);
        }

		this.getCommunities();
		FacebookService.getGroups();

		$rootScope.$emit('menu:refresh');
	}

	initChart(data) {
		this.invoke(function(gettextCatalog) {
			'ngInject';

			if(data && data.parentSittings && data.sitterSittings) {
				this.myChartObject = {};

				this.myChartObject.type = 'LineChart';
		        this.myChartObject.displayed = false;

		        var parsedData = {};

		        data.parentSittings.forEach(s => {
	        		parsedData[s._id.day + '/' + s._id.month] = {
	        			parentSittings: s.count
	        		}
		        })

		        data.sitterSittings.forEach(s => {
		        	if (!parsedData[s._id.day + '/' + s._id.month]) {
		        		parsedData[s._id.day + '/' + s._id.month] = {
		        			sitterSittings: s.count
		        		}
		        	} else {
		        		parsedData[s._id.day + '/' + s._id.month]['sitterSittings'] = s.count;
		        	}
		        })

		        var rows = [];
		        this.sCount = 0;
		        this.pCount = 0;
		        this.communityStats = [];

		        for(var key in parsedData) { 
				    rows.push({
			        		c: [{
			        			v: key
			        		}, {
			        			v: parsedData[key].parentSittings ? parsedData[key].parentSittings: 0
			        		}, {
			        			v: parsedData[key].sitterSittings ? parsedData[key].sitterSittings: 0
			        		}]
		        		}
		        	)

				    if (parsedData[key].parentSittings) {
				    	this.pCount += parsedData[key].parentSittings;
				    }

				    if (parsedData[key].sitterSittings) {
				    	this.sCount += parsedData[key].sitterSittings;
				    }

		        	this.communityStats.push({
		        		date: key,
		        		parentSittings: parsedData[key].parentSittings ? parsedData[key].parentSittings: 0,
		        		sitterSittings: parsedData[key].sitterSittings ? parsedData[key].sitterSittings: 0
		        	})
				}

		        this.myChartObject.data = {
		            'cols': [{
		                id: 'day',
		                label: gettextCatalog.getString('Day'),
		                type: 'string'
		            }, {
		                id: 'parent-sittings',
		                label: gettextCatalog.getString('Parent sittings'),
		                type: 'number'
		            }, {
		                id: 'sitter-sittings',
		                label: gettextCatalog.getString('Sitter sittings'),
		                type: 'number'
		            }],
		            rows: rows
		        };

		        this.myChartObject.options = {
		            title: gettextCatalog.getString('Sittings per day'),
		            colors: ['#009900', '#0000FF'],
		            defaultColors: ['#009900', '#0000FF'],
		            isStacked: true,
		            fill: 20,
		            displayExactValues: true,
		            vAxis: {
		            	format: '0',
		                title: gettextCatalog.getString('Sittings')
		            },
		            hAxis: {
		                title: gettextCatalog.getString('Date')
		            }
		        };

		        this.myChartObject.view = {
		            columns: [0, 1, 2]
		        };
			}
			
		})
	}

	viewCommunity(c) {
		this.invoke(function($state) {
			'ngInject';

			$state.go('community', {id: c._id});
		})
	}

	getCommunities() {
		this.invoke(function($state, $loader, CommunityService) {
			'ngInject';

			$loader.show();
			CommunityService.query().success(res => {
				this.communities = res;
				if (this.communities.length == 0) $state.go('new-community');

				if (this.communities[0]) this.selectedCommunity = this.communities[0]._id;

				$loader.hide();
			})
		})
	}
}

View.register(Home);