class MemberService extends Service {
    constructor() {
        super();
    }

    getMembers(communityId) {
        return this.invoke(function($http, $config) {
            'ngInject';

            return $http.get($config.apiUrl + '/member', {params: {communityId: communityId}});
        });
    }

    save(member) {
        return this.invoke(function($http, $config) {
            'ngInject';

            return $http.post($config.apiUrl + '/member', member);
        });
    }

    delete(members, communityId) {
        return this.invoke(function($http, $config) {
            'ngInject';

            return $http.delete($config.apiUrl + '/member', {params: {
                communityId: communityId, memberIds: members}
            });
        });
    }
}

Service.register(MemberService);