class Profile extends View {
	constructor() {
		super();
	}

	afterEnter(SessionService) {
		'ngInject';
		SessionService.refresh((u) => {
			this.user = u;
		})
		
	}

	save() {
		this.invoke(function($loader, SessionService, $mdToast, gettextCatalog) {
			'ngInject';

			$loader.show();
			var toastMessage = '';
			SessionService.updateUser(this.user).then((u) => {
				this.user = u;

				toastMessage = gettextCatalog.getString('Profile saved');
			}).catch(e => {
				toastMessage = gettextCatalog.getString('Error while saving profile');
			}).finally(() => {
				$mdToast.show(
                    $mdToast.simple()
                    .textContent(toastMessage)
                    .position('bottom right')
                    .hideDelay(3000)
                );
				$loader.hide()
			})
		})
	}
}

View.register(Profile);