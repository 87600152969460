// class MenuLink extends Directive {
// 	constructor() {
// 		super();
// 	}

// 	link($scope, $element, $attrs, ngModel) {
// 		console.log('MENU LINK LINK')
// 		var controller = $element.parent().controller();
// 		$scope.focusSection = function () {
// 	    	// set flag to be used later when
// 	    	// $locationChangeSuccess calls openPage()
// 	    	controller.autoFocusContent = true;
// 	  	};
// 	}
// }

// Directive.register(MenuLink, {
// 	scope: {
// 		section: '='
// 	}
// })

angular.module().directive('menuLink', function() {
	return {
		scope: {
			section: '='
		},
		templateUrl: 'menu-link.html',
		link: function($scope, $element) {
			var controller = $element.parent().controller();

			$scope.focusSection = function () {
				// set flag to be used later when
				// $locationChangeSuccess calls openPage()
				controller.autoFocusContent = true;
			}
		}
	}
});