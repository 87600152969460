class Community extends View {
	constructor(gettextCatalog) {
		super();

		this.communityTypes = [
			{name: gettextCatalog.getString('Public'), value: 'PUBLIC'},
			{name: gettextCatalog.getString('Confidential'), value: 'PRIVATE'}
			//{name: gettextCatalog.getString('Anonymous'), value: 'ANONYMOUS'}
		];

		this.promocodeTypes = [
			{name: gettextCatalog.getString('Free fees'), value: 'FREE_FEES'},
			{name: gettextCatalog.getString('Free fees first time'), value: 'FREE_FEES_FIRST_TIME'},
		];

		this.promocodeUseTypes = [
			{name: gettextCatalog.getString('Expirable'), value: 'EXPIRABLE'},
			{name: gettextCatalog.getString('Usable'), value: 'USABLE'}
		];

		this.memberTypes = [
			{name: gettextCatalog.getString('Parents'), value: 'PARENTS'},
			{name: gettextCatalog.getString('Sitters'), value: 'SITTERS'},
			{name: gettextCatalog.getString('Parents and Sitters'), value: 'MIXED'}
		];

		this.promoCodeStart = new Date();
		this.promoCodeEnd = new Date(Date.now() + 7*24*60*60*1000);

		this.options = {
			rowSelection: true,
			multiSelect: true,
			autoSelect: true,
			decapitate: false,
			largeEditDialog: false,
			boundaryLinks: false,
			limitSelect: true,
			pageSelect: true
		};

		this.query = {
			order: 'firstName',
			limit: 20,
			page: 1
		};
		this.selected = [];
	}

	afterEnter() {
		'ngInject';
		this.refreshCommunity();
	}

	changeStatus(e, status) {
		this.invoke(($loader, $mdDialog, $mdToast, gettextCatalog, CommunityService) => {
			'ngInject';

			var confirm = $mdDialog.confirm()
		          .title(gettextCatalog.getString('Would you like to submit your community for review?'))
		          .textContent(gettextCatalog.getString("While we'll be reviewing your community you will still be able to add members but not edit other informations."))
		          .ariaLabel(gettextCatalog.getString('Submit for review'))
		          .targetEvent(e)
		          .ok(gettextCatalog.getString('Submit for review'))
		          .cancel(gettextCatalog.getString('Cancel'));

		    $mdDialog.show(confirm).then(() => {
		    	$loader.show();
		    	var memberIds = this.selected.map(s => s._id);

				CommunityService.changeStatus(this.community, status).success(data => {
					$mdToast.show(
						$mdToast.simple()
						.content(gettextCatalog.getString('Community sent to validation'))
						.position('bottom right')
						.hideDelay(3000)
					);
					this.refreshCommunity();
				}).error(err => {
					$mdToast.show(
						$mdToast.simple()
						.content(gettextCatalog.getString('Error while submitting community'))
						.position('bottom right')
						.hideDelay(3000)
					);
					$loader.hide();
				})
		    });
		})
	}

	saveCommunity() {
		this.invoke(($loader, $mdDialog, $mdToast, gettextCatalog, CommunityService) => {
			'ngInject';

			if (this.promoCodeStart && this.community.promoCode.tempEnd) {
				this.community.promoCode.start = new Date(this.promoCodeStart).toISOString();
				this.community.promoCode.end = new Date(this.promoCodeEnd).toISOString();
			}

			$loader.show();
			CommunityService.save(this.community).success((res) => {
				this.community = res;
				this.hasPromoCode = res.promoCode.code;
				$mdToast.show(
					$mdToast.simple()
					.content(gettextCatalog.getString('Community saved'))
					.position('bottom right')
					.hideDelay(3000)
				);
				$loader.hide()
			}).error(err => {
				$mdToast.show(
					$mdToast.simple()
					.content(gettextCatalog.getString(err && err.error && err.error.message ? err.error.message: 'Error while saving community'))
					.position('bottom right')
					.hideDelay(3000)
				);
				$loader.hide()
			})
		})
	}

	refreshCommunity() {
		this.invoke(($loader, $stateParams, CommunityService, MemberService) => {
			'ngInject';

			$loader.show();
			CommunityService.get($stateParams.id).success((c) => {
				this.community = c;
				this.hasPromoCode = c.promoCode.code;

				MemberService.getMembers(c._id).success((members) => {
					this.members = members;
					$loader.hide();
				});
			})
		})
	}

	refreshMembers() {
		this.invoke(($loader, MemberService) => {
			'ngInject';

			if(this.community) {
				$loader.show();
				MemberService.getMembers(this.community._id).success((members) => {
					this.members = members;
					$loader.hide();
				});
			}
		})
	}

	changeActive(m) {
		this.invoke((MemberService) => {
			'ngInject';

			MemberService.save(m);
		})
	}

	getMembers() {
		this.invoke((MemberService) => {
			'ngInject';

			if(this.community) {
				MemberService.getMembers(this.community._id).success((members) => {
					this.members = members;
				});
			}
		})
	}

	addAdmin() {
		this.invoke(($loader, $mdDialog, $mdToast, gettextCatalog, CommunityService) => {
			'ngInject';

			CommunityService.save({
				newAdmin: this.newAdminEmail,
				community: this.community._id
			}).success((data) => {
				$mdToast.show(
					$mdToast.simple()
					.textContent(gettextCatalog.getString('Admin added'))
					.position('bottom right')
					.hideDelay(3000)
				);

				this.newAdminEmail = '';
			}).error((err) => {
				$loader.hide();
				$mdToast.show(
					$mdToast.simple()
					.textContent(gettextCatalog.getString('Error while adding admin'))
					.position('bottom right')
					.hideDelay(3000)
				);
			})
		})
	}

	deleteAdmin(e) {
		// Server: Community.model.update({}, { $pull: { admins: e.target.parentNode.parentNode.children[1].innerText } });
	}

	addMember(e) {
		this.invoke(($mdDialog) => {
			'ngInject';

			$mdDialog.show({
				controller: ($scope, $loader, $mdToast, gettextCatalog, MemberService) => {
					$scope.close = () => {
						$mdDialog.hide();
					}

					$scope.saveMember = () => {
						if(!$scope.newMember.email && !$scope.newMember.phoneNumber) {
							$mdToast.show(
			                    $mdToast.simple()
			                    .textContent(gettextCatalog.getString('Email or phone number is required'))
			                    .position('bottom right')
			                    .hideDelay(3000)
			                );
							return;
						}

						$loader.show();
						$scope.newMember.community = this.community._id;

						MemberService.save($scope.newMember).success((data) => {
							this.hasPromoCode = data.promoCode.code;
							this.refreshMembers();
							$mdToast.show(
			                    $mdToast.simple()
			                    .textContent(gettextCatalog.getString('Member added'))
			                    .position('bottom right')
			                    .hideDelay(3000)
			                );

			                $scope.newMember = {
								firstName: '',
								lastName: '',
								email: '',
								phoneNumber: ''
							};
						}).error((err) => {
							$loader.hide();
							$mdToast.show(
			                    $mdToast.simple()
			                    .textContent(gettextCatalog.getString('Error while adding member'))
			                    .position('bottom right')
			                    .hideDelay(3000)
			                );
						})
					}
				},
				focusOnOpen: false,
				targetEvent: e,
				templateUrl: 'add-member-dialog.html',
				parent: angular.element(document.body)
			});
		})
	}

	deleteMembers(e) {
		this.invoke(($loader, $mdDialog, $mdToast, gettextCatalog, MemberService) => {
			'ngInject';

		    var confirm = $mdDialog.confirm()
		          .title(gettextCatalog.getString('Would you like to delete') + ' ' + this.selected.length + ' ' + (this.selected.length == 1 ? gettextCatalog.getString('member') : gettextCatalog.getString('members') + '?'))
		          .textContent(gettextCatalog.getString('You can not retrieve deleted members.'))
		          .ariaLabel(gettextCatalog.getString('Delete members'))
		          .targetEvent(e)
		          .ok(gettextCatalog.getString('Delete members'))
		          .cancel(gettextCatalog.getString('Cancel'));

		    $mdDialog.show(confirm).then(() => {
		    	$loader.show();
		    	var memberIds = this.selected.map(s => s._id);

				MemberService.delete(memberIds, this.community._id).success((data) => {
					this.selected = [];
					$mdToast.show(
						$mdToast.simple()
						.content(gettextCatalog.getString('Members deleted'))
						.position('bottom right')
						.hideDelay(3000)
					);
					this.refreshCommunity();
				}).error((err) => {
					$mdToast.show(
						$mdToast.simple()
						.content(gettextCatalog.getString('Error while deleting member'))
						.position('bottom right')
						.hideDelay(3000)
					);
					$loader.hide();
				})
		    });
		})
	}	

	uploadFile(ev) {
		this.invoke(($mdDialog, $mdMedia) => {
			'ngInject';

			$mdDialog.show({
				controller: ($scope, $http, $config, $loader, $mdDialog, $s3Upload, $mdToast, uuid, gettextCatalog) => {
					var maxSize = 10;

					$scope.close = () => {
						$mdDialog.hide();
					}

					$scope.parseFile = (fileId) => {
						$loader.show();
						return $http.post($config.apiUrl + '/parse', {fileId: fileId, communityId: this.community._id}).success(() => {
							this.refreshCommunity();
							$mdToast.show(
								$mdToast.simple()
								.content(gettextCatalog.getString('Members added'))
								.position('bottom right')
								.hideDelay(3000)
							);
						});
					}

					$scope.$watch('files', (files) => {
						if(maxSize && files) {
							if (maxSize && files && files[files.length-1].size > maxSize * 1024 * 1024) {
								files.pop();
								$mdToast.show(
									$mdToast.simple()
									.content(gettextCatalog.getString('Looks like your file is quite big'))
									.position('bottom right')
									.hideDelay(3000)
								);
							} else {
								if(files[files.length-1].type != 'text/csv' && files[files.length-1].type != 'application/vnd.ms-excel') {
									files.pop();
									$mdToast.show(
										$mdToast.simple()
										.content(gettextCatalog.getString('You can only upload CSV files'))
										.position('bottom right')
										.hideDelay(3000)
									);
								} else {
									doUpload(files[files.length-1])
								}
							}
						}
				    });

					var doUpload = (file) => {
						$loader.show();
						$scope.uploading = true;
						var fileId = uuid();
						$s3Upload(fileId, file, {
							requestType: 'PUT'
						}).then((result) => {
							$scope.uploading = false;
							$scope.close();
							$loader.hide();
							$scope.parseFile(fileId);
						}, (data, status) => {
							$loader.hide();
						})
					}
				},
				templateUrl: 'upload-file-dialog.html',
				parent: angular.element(document.body),
				targetEvent: ev,
				fullscreen: $mdMedia('xs') || $mdMedia('sm')
			})
		})
	}

	clickMember(e, member) {
		this.invoke(($mdDialog) => {
			'ngInject';

			memberPreEdition = {
				firstName: member.firstName,
				lastName: member.lastName,
				email: member.email,
				phoneNumber: member.phoneNumber
			}
			$mdDialog.show({
				controller: ($scope, $loader, $mdToast, gettextCatalog, MemberService) => {
					$scope.newMember = member;
					$scope.close = (save) => {
						$mdDialog.hide();

						memberPostEdition = {
							firstName: $scope.newMember.firstName,
							lastName: $scope.newMember.lastName,
							email: $scope.newMember.email,
							phoneNumber: $scope.newMember.phoneNumber
						}

						//If we close the modal whitout saving ensure that the edition is not taken
						if (!save && memberPreEdition !== memberPostEdition) {
							$scope.newMember.firstName = memberPreEdition.firstName
							$scope.newMember.lastName = memberPreEdition.lastName
							$scope.newMember.email = memberPreEdition.email
							$scope.newMember.phoneNumber = memberPreEdition.phoneNumber							
						}
					}

					$scope.saveMember = () => {
						if(!$scope.newMember.email && !$scope.newMember.phoneNumber) {
							$mdToast.show(
			                    $mdToast.simple()
			                    .textContent(gettextCatalog.getString('Email or phone number is required'))
			                    .position('bottom right')
			                    .hideDelay(3000)
			                );
							return;
						}

						$loader.show();
						$scope.newMember.community = this.community._id;

						MemberService.save($scope.newMember).success((data) => {
							this.refreshMembers();
							member = data;
							$loader.hide();

							$mdToast.show(
			                    $mdToast.simple()
			                    .textContent(gettextCatalog.getString('Member saved'))
			                    .position('bottom right')
			                    .hideDelay(3000)
			                );
						}).error((err) => {
							$loader.hide();
							$mdToast.show(
			                    $mdToast.simple()
			                    .textContent(gettextCatalog.getString('Error while adding member'))
			                    .position('bottom right')
			                    .hideDelay(3000)
			                );
						}).then(() => {
							$scope.close(true);
						})
					}
				},
				focusOnOpen: false,
				targetEvent: e,
				templateUrl: 'add-member-dialog.html',
				parent: angular.element(document.body)
			});
		})
	}
}

View.register(Community, {
    route: '/community/:id'
});
