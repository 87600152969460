class Toolbar extends View {
	constructor(SessionService, $scope) {
		'ngInject';

		super();
		SessionService.on('login', (e, u) => $scope.view.user = u);
		SessionService.on('update', (e, u) => $scope.view.user = u);
		this.user = SessionService.getSession().getUser();
	}

	isLogged() {
		var loggedIn;
		this.invoke((SessionService) => {
			'ngInject';

			loggedIn = SessionService.isLoggedIn();
		})

		return loggedIn;
	}

	logout() {
		this.invoke((popup, gettextCatalog, SessionService, $state) => {
			'ngInject';

			popup.confirm(
				gettextCatalog.getString('Logout'),
				gettextCatalog.getString('Are you sure?')
			).then((res) => {
				if (res) SessionService.logout().then(() => $state.go('intro'));
			})
		})
	}
}

//The toolbar is a view without a route
View.register(Toolbar, {
	noroute: true
});